@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('assets/css/_image.css');
@import url('assets/css/reset.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,200;1,400&display=swap');

:root {
  font-size: calc((100vw * 16) / 1440);
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

p,
span,
li,
button {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
p,
a,
span {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.5rem;
  font-weight: 600;
}

h2 {
  font-size: 3rem;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.slick-dots li {
  width: 0;
  height: 0;
  margin: 0 10px;
}

@media screen and (max-width: 48em) {
  :root {
    font-size: calc((100vw * 16) / 360);
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.75rem;
  }
}
