.image__hero {
  width: 90%;
  height: 90%;
}

.image__logo {
  width: 10rem;
}

.image__contact {
  width: 60px;
}

.image__migration {
  width: 50%;
  height: 50%;
}

.image__development {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 48em) {
  .image__logo {
    width: 8rem;
  }

  .image__contact {
    width: 48px;
  }

  .image__hero,
  .image__migration,
  .image__development {
    width: 100%;
    height: 100%;
  }
}
